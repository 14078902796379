import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Start.vue';
import Overview from '../views/Overview.vue';
import Intro from '../views/Intro.vue';

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/anniversary/abriefhistory',
  },
  {
    path: '/intro',
    name: 'Intro',
    component: Intro,
  },

  // Anniversary
  {
    path: '/overview/',
    name: 'Overview',
    component: Overview,
  },
  {
    path: '/anniversary',
    redirect: '/anniversary/abriefhistory',
  },
  {
    path: '/anniversary/abriefhistory',
    name: 'Abriefhistory',
    component: () => import(/* webpackChunkName: "anniversary-abriefhistory" */ '../views/rooms/Abriefhistory.vue'),
  },

  // Meesse Booth rooms
  {
    path: '/rooms/',
    name: 'Rooms',
    component: Home,
  },
  {
    path: '/rooms/endo-tower',
    redirect: '/rooms/or',
  },
  {
    path: '/rooms/products',
    redirect: '/rooms/pain-therapy',
  },
  {
    path: '/rooms/spinal-stabilization',
    name: 'Spinal Stabilization',
    component: () => import(/* webpackChunkName: "rooms-spinal-stabilization" */ '../views/rooms/SpineStabilization.vue'),
  },
  {
    path: '/rooms/pain-therapy',
    name: 'Pain Therapy',
    component: () => import(/* webpackChunkName: "rooms-pain-therapy" */ '../views/rooms/PainTherapy.vue'),
  },
  {
    path: '/rooms/decompression',
    name: 'Decompression',
    component: () => import(/* webpackChunkName: "rooms-decompression" */ '../views/rooms/Decompression.vue'),
  },
  {
    path: '/rooms/or',
    name: 'OR',
    component: () => import(/* webpackChunkName: "rooms-op" */ '../views/rooms/Or.vue'),
  },
  {
    path: '/rooms/education',
    name: 'Education',
    component: () => import(/* webpackChunkName: "rooms-education" */ '../views/rooms/Education.vue'),
  },
  {
    path: '/rooms/about',
    name: 'Joimax',
    component: () => import(/* webpackChunkName: "rooms-about" */ '../views/rooms/About.vue'),
  },
  {
    path: '/rooms/chat',
    name: 'Chatroom',
    component: () => import(/* webpackChunkName: "rooms-chat" */ '../views/rooms/Chat.vue'),
  },
  {
    path: '/rooms/scientific',
    name: 'Scientific',
    component: () => import(/* webpackChunkName: "rooms-scientific" */ '../views/rooms/Scientific.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
