










































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LongButton extends Vue {
  @Prop() private target!: string;

  @Prop() private pulsate!: boolean;

  @Prop() private index!: number;

  public show = false;

  mounted() {
    if (this.index !== undefined) {
      setTimeout(() => {
        this.show = true;
      }, this.index * 300);
    } else {
      this.show = true;
    }
  }

  get link() {
    let prefix = '';
    if (this.target === 'anniversary' || this.target === 'overview') {
      prefix = '';
    // } else if (this.target === 'rooms') {
      // prefix = 'rooms/';
    } else if (this.target !== 'start' && this.target !== 'intro' && this.target !== 'rooms') {
      prefix = 'rooms/';
    }
    return `/${prefix}${this.target === 'start' ? '' : this.target}`;
  }
}
