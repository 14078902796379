





import { Component, Vue } from 'vue-property-decorator';
import LongButton from '@/components/LongButton.vue'; // @ is an alias to /src

@Component({
  components: {
    LongButton,
  },
})
export default class Intro extends Vue {
  /* eslint-disable class-methods-use-this */
  created() {
    window.location.replace('/');
  }
}
