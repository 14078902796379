




















































































import {
  Component,
  Ref,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { Route } from 'vue-router';

import LongButton from '@/components/LongButton.vue';

import routeNameToIdent from '@/helper/routeNameToIdent';
import videoHelper from '@/helper/videoHelper';

@Component({
  components: {
    LongButton,
  },
})
export default class App extends Vue {
  public showVideo = false;

  public showSpinner = true;

  public videoSrc = '';

  public preloadedVideos = 0;

  public videoHelper = videoHelper;

  public loadingPercent = 0;

  public windowWidth = window.innerWidth;

  @Ref() readonly app!: HTMLDivElement

  @Watch('$route')
  onRouteChanged(to: Route, from: Route) {
    this.showVideo = false;
    const fileName = `${routeNameToIdent(from.name)}-${(routeNameToIdent(to.name))}`;
    // console.log('load video: ', fileName);
    if (Object.keys(videoHelper).includes(fileName)) {
      this.videoSrc = videoHelper[fileName];
      this.showVideo = true;
    }
  }

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }

  get routeIdent() {
    return (this.$route.name || 'start').toLowerCase().replace(/ /g, '-');
  }

  get isSmallScreen() {
    return this.windowWidth < 1000;
  }

  onVideoEnded() {
    this.showVideo = false;
    this.videoSrc = '';
  }

  onVideoLoaded() {
    this.preloadedVideos += 1;
    const total = Object.keys(videoHelper).length + 9;
    this.loadingPercent = Math.ceil((this.preloadedVideos / total) * 100);

    if (this.preloadedVideos === total) { // *9 because of images
      if (this.$route.name === 'Rooms') {
        this.videoSrc = videoHelper.intro;
        this.showVideo = true;
      } else {
        const fileName = `start-${(routeNameToIdent(this.$route.name))}`;
        if (Object.keys(videoHelper).includes(fileName)) {
          this.videoSrc = videoHelper[fileName];
          this.showVideo = true;
        }
      }
      this.showSpinner = false;
    }
  }

  onResize() {
    this.windowWidth = window.innerWidth;
  }
}
