const videos: {[index: string]: string} = {
  intro: 'Intro_Rundflug',

  'start-chatroom': 'TopDown_Chatroom',
  'rooms-chatroom': 'TopDown_Chatroom',

  'start-education': 'TopDown_Education',
  'rooms-education': 'TopDown_Education',

  'start-joimax': 'TopDown_Joimax',
  'rooms-joimax': 'TopDown_Joimax',

  'start-or': 'TopDown_Operation',
  'rooms-or': 'TopDown_Operation',

  'start-pain_therapy': 'TopDown_Solutions',
  'rooms-pain_therapy': 'TopDown_Solutions',

  'start-scientific': 'TopDown_Scientific',
  'rooms-scientific': 'TopDown_Scientific',

  'start-abriefhistory': 'Anni_Abriefhistory',
  'overview-abriefhistory': 'Anni_Abriefhistory',

  'spinal_stabilization-pain_therapy': 'Pain_Spinal_Morph_Reverse',
  'pain_therapy-spinal_stabilization': 'Pain_Spinal_Morph_Normal',

  'pain_therapy-decompression': 'Pain_Decom_Morph_Normal',
  'decompression-pain_therapy': 'Pain_Decom_Morph_Reverse',
};
export default videos;
