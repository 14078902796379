












import { Component, Vue } from 'vue-property-decorator';
import LongButton from '@/components/LongButton.vue';

@Component({
  components: {
    LongButton,
  },
})
export default class Overview extends Vue {
}
